@import '_globals';


@import 'bulma';


html {
    max-height: 100vh;
    overflow-y: visible;
}

#App {
    display: flex;
    height: 100%;
    margin-left: $nav-width;

    // Router wrapper generated by reach router
    > div[tabindex='-1'] {
        flex-grow: 1;
        height: 100%;
    }
}

// For some reason bulma doesn't have this in their core project
.card-header-icon {
    background: 0 0;
    border: none;
}

.card-content {
    word-break: break-all;
}

button {
    cursor: pointer;
}

.manager-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 5px;
    background: $white-bis;
}

.no-button {
    background: none;
    border: none;
}

[data-reach-menu-item] {
    cursor: pointer;
    color: $dropdown-item-color;

    &[data-selected] {
        color: $dropdown-item-hover-color;
        background: $dropdown-item-hover-background-color;
    }
}