#CopyInstance {
    h2 {
        font-weight: bold;
        margin: 20px 0 0;
    }

    table {
        margin-left: 20px;
        border: 1px solid black;
    }

    td, th {
        border: 1px solid black;
        padding: 5px;
        min-width: 150px;
    }
}