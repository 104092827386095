@import '_globals';


// html, body, div, p, a, h1, h2, h3, h4, h5, button {
//     font-family: 'Courier New', Courier, monospace;
//     font-size: 14px;
//     color: white;
//     margin: 0;
//     padding: 0;
// }

html, body, #manager-root {
    height: 100%;
}

i {
    &.fa, &.fas {
        font-style: normal;
        font-family: 'FontAwesome';
    }
}

// h1 {
//     font-size: 20px;
// }

// h2 {
//     font-size: 16px;
// }

// a {
//     font-weight: bold;
//     text-decoration: none;
// }

// a:hover {
//     text-decoration: underline;
// }

// button {
//     cursor: pointer;
// }

// p {
//     margin: 10px 0;
// }

// button {
//     margin-right: 10px;
//     background: black;
//     border: 1px solid grey;
//     color: white;
//     border-radius: 0;
//     padding: 5px 10px;
//     min-width: 100px;
// }

// button:hover {
//     border: 1px solid white;
// }

// .animated-label {
//     position: relative;
//     padding-top: 12px;
//     margin-top: 9px;
//     margin-bottom: 5px;
//     font-size: 12px;
// }

// .animated-label > label {
//     position: absolute;
//     top: 10;
//     left: 5px;
//     margin: 0;
//     transition: top 0.2 ease-in-out, font-size 0.2 ease-in-out, font-weight 0.2 ease-in-out;
//     transition-delay: 0.2;
//     color: #888;
//     font-weight: 300;
//     pointer-events: none;
//     text-align: left;
//     width: auto;
// }

// .animated-label > label.right {
//     left: auto;
//     right: 12px;
//     text-align: right;
// }

// .animated-label > input, .animated-label > select, .animated-label > textarea {
//     width: 100%;
//     font-size: 14px;
//     border-radius: 2px;
//     padding: 10px 2px 2px 2px;
//     resize: none;
//     border: 1px solid #d8d8d8;
//     color: #888;
//     font-weight: 300;
//     height: 36px;
//     min-height: 36px;
//     cursor: auto;
// }

// .animated-label > input::placeholder, .animated-label  > select::placeholder, .animated-label > textarea::placeholder {
//     transition: opacity 0.2 ease-in-out;
//     opacity: 1;
// }

// .animated-label .active::placeholder {
//     opacity: 0;
// }

// .animated-label.focused-add-active::placeholder, .animated-label.focused-remove-active::placeholder {
//     opacity: 0;
// }
