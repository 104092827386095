@import "../..//index.scss";

.time-zone-picker {
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 1;
}

.diagnostics-route {
    height: var(--innerHeight);
}
// #dataContainer {
//     margin-top: 10px;
//     display: flex;
// }

// #sidebar {
//     width: 200px;
//     background: grey;
//     height: 100%;
//     padding: 10px;
//     border-radius: 10px;
// }

// #sidebar ul {
//     margin: 0;
//     padding: 0;
// }

// #sidebar li {
//     margin: 0;
//     padding: 25px;
//     list-style-type: none;
//     border-bottom-left-radius: 10px;
// }

// #sidebar li:hover, #sidebar li.active {
//     color: black;
//     cursor: pointer;
//     background: lightgray;
// }

// #content {
//     flex-grow: 1;
//     padding: 10px;
// }
