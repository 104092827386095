@import "../index.scss";

#manager-menu {
    box-shadow: 3px -3px 10px 0px lightgrey;
    position: fixed;
    left: 0;
    width: $nav-width;
    height: 100vh;
}

.menu-list a {
    border-radius: 0;

    &:hover {
        text-decoration: none;
    }
}

.logo {
    position: relative;
    padding-top: 5px;

    &:after {
        content: "";
        width: 100%;
        height: 5px;
        top: 0;
        background-color: $primary;
        position: absolute;
    }
}
