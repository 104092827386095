/* #Installations #segments section {
    margin: 20px 0;    
}

#Installations #segments section header {
    font-size: 18px;
}

#Installations #segments section header div {
    font-size: 12px;
    color: grey;
}

#Installations #segments section .inner {
    transition: opacity 1s ease-out;
}

#Installations #segments section .segment {
    margin: 20px;
}

#Installations #segments section .segment .installation {
    margin: 10px 20px;
}

#Installations .dialog {
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    padding: 20px;
    box-shadow: -5px -5px 5px black;
    background: linear-gradient(130deg, rgba(0,0,0,1) 50%, rgba(149,191,60,1) 100%);
}

#Installations .confirm.dialog {
    z-index: 900;
}

#Installations a.delete {
    color: red;
}

#Installations .installation a.delete {
    margin-right: 10px;
}
*/

#Installations .search-results {
    z-index: 20;
    position: absolute;
    top: 0;
    background: #fff;
    maxWidth: 500px,
}