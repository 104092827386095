$causelinkGreen: #94ad34;
$causelinkLightGreen: #c2cc9c;
$causelink50: #d5dfb3;
$causelink25: #e3eec0;
$warningColor: #ac5e5e;
$nav-width: 13rem;

@import '~bulma/sass/utilities/initial-variables';

// Bulma variables
$primary: $causelinkGreen;
$info: #40a4a6;
$danger: $warningColor;
$link: $causelinkGreen;
$link-invert: $black-bis;
$menu-item-active-color: $white;
$menu-item-hover-background-color: $white;
$menu-item-hover-color: $primary;
$button-static-color: #4a4a4a;

@import '~bulma/sass/utilities/derived-variables';