.section {
    .releaseItemGroup {
        border: 1px solid black;
        border-radius: 3px;
        margin-bottom: 20px;

        h4 {
            margin: 10px;
            font-size: 16px;
            font-weight: bold;
        }

        .envcontainer {
            display: flex;
            margin: 10px;
            overflow-x: scroll;

            h5 {
                font-size: 14px;
                font-weight: bold;
            }

            .env {
                flex-basis: 300px;
                flex-grow: 0;
                flex-shrink: 0;
                padding: 10px;
                margin-right: 10px;
                border: 1px solid grey;

                .itemList {
                    height: 200px;
                    overflow-y: scroll;

                    .releaseItem {
                        padding: 5px;

                        .data {
                            display: flex;
                            justify-content: space-between;
                            align-items: center; /* Vertically align items if needed */
                            width: 100%; /* Ensure it spans the container width */

                            &>div {
                                flex-shrink: 0;
                            }

                            .right {
                                margin-left: auto;
                            }
                        }

                        .smaller {
                            font-size: 12px;
                        }
                    }

                    .releaseItem:nth-child(odd) {
                        background-color: #EFEFEF;
                    }

                    button {
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}