#ReplayPanel {
    .back-button {
        height: 2rem;
        padding: 0;
    }
}
#ReplayPanel .entry {
    display: flex;
    font-weight: bold;
}

#ReplayPanel .entry.click {
    color: rgb(15, 130, 202);
}

#ReplayPanel .entry.navigate {
    color: rgb(255, 166, 0);
}

#ReplayPanel .entry.error {
    color: rgb(255, 90, 90);
}

#ReplayPanel .entry.alt {
    background: rgb(247, 255, 241);
}

#ReplayPanel .entry .at {
    margin-right: 10px;
}