#diagnosticInput {
    display: flex;
    flex-direction: column;
}

#diagnosticInput > div {
    vertical-align: top;
    margin: 25px;
}

#diagnosticInput textarea {
    width: 100%;
    height: 200px;
}

#diagnosticInput .error {
    color: red;
    font-size: 10px;
}