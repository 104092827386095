#SegmentDetails label {
    display: block;
    margin: 0;
}

#SegmentDetails label input {
    margin: 10px;
}

#SegmentDetails .card-header .secondary {
    flex-grow: 1;
    width: 100%;
    display: flex;
}

#SegmentDetails .overview {
    display: flex;
}

#SegmentDetails .overview div {
    margin-right: 10px;
}

#SegmentDetails .card-content.differentVersion {
    background-color: #f0f5f1;
}

#SegmentDetails .card-content span {
    font-weight: bold;
}

#SegmentDetails .card-content p {
    margin: 10px 0;
}