@import '../../../../index';

#StoryPanel > div {
    margin: 10px 10px 10px 25px;
    padding: 10px;
    position: relative;
    font-weight: bold;
}

#StoryPanel .offset {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background: #9eb8b8;
}

#StoryPanel .navigate {
    margin-left: 0;
    font-size: 25px;
    font-weight: bold;
}

#StoryPanel .clicked {
    background-color: #B6D5D5;
}

#StoryPanel .warnerror {
    background-color: #ff6d6d;
}

#StoryPanel .Undo > div:first-child, #StoryPanel .Redo > div:first-child {
    display: inline-block;
}

#StoryPanel .Undo {
    background-color: rgb(162, 253, 255);
}

#StoryPanel .Undo:before {
    background-color: black;
    padding: 5px;
    content: '⟲';
    text-align: center;
    margin: 5px;
    opacity: .75;
    display: inline-block;
}

#StoryPanel .Redo {
    background-color: rgb(146, 200, 146);
}

#StoryPanel .Redo:before {
    background-color: black;
    padding: 5px;
    content: '⟳';
    text-align: center;
    margin: 5px;
    opacity: .75;
}
