@import "../../../../index";


#SessionPanel {
    height: 100%;
    display: flex;
    flex-flow: column;
    $filterHeight: 0px;

    .filter {
        // position: sticky;
        // height: $filterHeight;
        background-color: $white-bis;
        width: 100%;
        top: 0;
        z-index: 1;
    }

    .list {
        height: calc(100% - #{$filterHeight});
        display: flex;

        .entries {
            width: 50%;
            height: 100%;
            overflow-y: auto;
        }

        .details {
            overflow-y: auto;
            height: 100%;
            width: 50%;
            background-color: $white-bis;
        }
    }
}

#SessionPanel .entry {
    cursor: pointer;
    border-bottom: 1px solid white;
    padding: 5px;
    border: 1px solid white;
    font-weight: 500;
}

#SessionPanel .entry:hover,
#SessionPanel .entry.active {
    border-right: 10px solid rgb(255, 255, 255);
}

#SessionPanel .entry.trace {
    background: rgb(92, 231, 255);
}

#SessionPanel .entry.trace.request {
    background: rgb(204, 248, 224);
}

#SessionPanel .entry.trace.request::before {
    content: "> ";
}

#SessionPanel .entry.trace.response {
    background: rgb(207, 229, 255);
}

#SessionPanel .entry.trace.response::before {
    content: "< ";
}

#SessionPanel .entry.debug {
    background: rgb(116, 255, 116);
}

#SessionPanel .entry.info {
    background: rgb(236, 131, 255);
}

#SessionPanel .entry.warn {
    background: rgb(255, 196, 85);
}

#SessionPanel .entry.error {
    background: rgb(255, 109, 109);
}
