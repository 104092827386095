@import "../../../globals";

#wait-container {
        opacity: 0;
        transition: opacity 0.5s ease;

        .fade-in {
                opacity: 1;
        }
}
#wait-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
        top: 0;
        left: 0;
}

#wait-panel {
        animation: spinAround 0.5s infinite linear;
        border: 0.5em solid #dbdbdb;
        border-radius: 9999px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        width: 10em;
        height: 10em;
        z-index: 1;
        position: fixed;
        top: calc(50vh - 10em);
        left: calc(50vw - 10em);
}
